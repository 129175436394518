import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import Image from '../components/Image'

const TeamSection = ({ heading, people }) => {
    return (
        <div className={cx('mb-80')}>
            {heading && <h2 className={cx('hd-sm mb-80')}>{heading}</h2>}

            <ul className={cx('flex flex-wrap -mx-20 -mb-40')}>
                {people.map((person) => (
                    <li
                        key={person.name}
                        className={cx(
                            'flex justify-between w-full px-20 mb-40 md:w-1/2'
                        )}
                    >
                        {person.headshot && (
                            <div
                                className={cx(
                                    'pr-20 flex-shrink-0 max-w-310 md:pr-40 md-d:w-1/3 md:w-1/2 lg:w-full'
                                )}
                            >
                                <Image image={person.headshot} />
                            </div>
                        )}
                        <div className={cx('flex-grow md:w-1/2 lg:w-auto')}>
                            <p className={cx('font-medium mb-8')}>
                                {person.name}
                            </p>
                            <p
                                className={cx(
                                    'font-medium text-gray-500 text-14 mb-12'
                                )}
                            >
                                {person.title}
                            </p>
                            {person.bio && <p>{person.bio}</p>}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

TeamSection.propTypes = {
    heading: PropTypes.string,
    people: PropTypes.array,
}

export default TeamSection
