import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import TeamSection from '../components/TeamSection'
import Image from '../components/Image'
import PageBackgroundImage from '../components/PageBackgroundImage'
import LogoGrid from '../components/LogoGrid'

export const OurTeamPageTemplate = ({
    title,
    introduction,
    backgroundImage,
    featuredImage,
    leadershipTeamHeading,
    leadershipTeam,
    boardDirectorsHeading,
    boardOfDirectors,
    teamHeading,
    team,
    logoGrid,
    scientificAdvisoryBoard,
    scientificAdvisoryBoardHeading,
}) => {
    return (
        <section
            className={cx('max-w-1264 mx-auto px-24 lg:px-32 py-60 lg:pb-120')}
        >
            {backgroundImage && <PageBackgroundImage image={backgroundImage} />}

            <h1 className={cx('hd-jb text-purple-700 mb-24')}>{title}</h1>
            {introduction && (
                <p className={cx('lg:w-1/2 text-21 leading-snug mb-80')}>
                    {introduction}
                </p>
            )}

            {featuredImage && (
                <Image image={featuredImage} className={cx('mb-80')} />
            )}

            {leadershipTeam && (
                <TeamSection
                    heading={leadershipTeamHeading}
                    people={leadershipTeam}
                />
            )}

            {team && (
                <div
                    className={cx(
                        'mb-80 py-80 border-gray-100 border-t border-b'
                    )}
                >
                    {teamHeading && (
                        <h2 className={cx('hd-sm mb-80')}>{teamHeading}</h2>
                    )}

                    <ul className={cx('flex flex-wrap -mx-20 -mb-40')}>
                        {team.map((person) => (
                            <li
                                key={person.name}
                                className={cx(
                                    'px-20 mb-40 text-center w-1/2 md:w-1/4'
                                )}
                            >
                                {person.headshot && (
                                    <Image
                                        image={person.headshot}
                                        className={cx('mb-20 mx-auto')}
                                    />
                                )}
                                <p className={cx('font-medium mb-8')}>
                                    {person.name}
                                </p>
                                <p
                                    className={cx(
                                        'font-medium text-gray-500 text-14 mb-12'
                                    )}
                                >
                                    {person.title}
                                </p>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {boardOfDirectors && (
                <TeamSection
                    heading={boardDirectorsHeading}
                    people={boardOfDirectors}
                />
            )}

            {scientificAdvisoryBoard && (
                <div
                    className={cx(
                        'mb-80 pt-80 border-gray-100 border-t border-b'
                    )}
                >
                    <TeamSection
                        heading={scientificAdvisoryBoardHeading}
                        people={scientificAdvisoryBoard}
                    />
                </div>
            )}

            {logoGrid && <LogoGrid logoGrid={logoGrid} className="mt-60" />}
        </section>
    )
}

OurTeamPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    introduction: PropTypes.string,
    backgroundImage: PropTypes.object,
    featuredImage: PropTypes.object,
    leadershipTeam: PropTypes.array,
    leadershipTeamHeading: PropTypes.string,
    boardOfDirectors: PropTypes.array,
    boardDirectorsHeading: PropTypes.string,
    scientificAdvisoryBoard: PropTypes.array,
    scientificAdvisoryBoardHeading: PropTypes.string,
    team: PropTypes.array,
    teamHeading: PropTypes.string,
    logoGrid: PropTypes.shape({
        heading: PropTypes.string,
        logoGridImages: PropTypes.array,
    }),
}

const OurTeamPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark

    return (
        <Layout
            title={frontmatter.title}
            description={frontmatter.introduction}
        >
            <OurTeamPageTemplate
                title={frontmatter.title}
                introduction={frontmatter.introduction}
                backgroundImage={frontmatter.backgroundImage}
                featuredImage={frontmatter.featuredImage}
                leadershipTeam={frontmatter.leadershipTeam}
                leadershipTeamHeading={frontmatter.leadershipTeamHeading}
                boardOfDirectors={frontmatter.boardOfDirectors}
                boardDirectorsHeading={frontmatter.boardDirectorsHeading}
                team={frontmatter.team}
                teamHeading={frontmatter.teamHeading}
                logoGrid={frontmatter.logoGrid}
                scientificAdvisoryBoard={frontmatter.scientificAdvisoryBoard}
                scientificAdvisoryBoardHeading={
                    frontmatter.scientificAdvisoryBoardHeading
                }
            />
        </Layout>
    )
}

OurTeamPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default OurTeamPage

export const ourTeamPageQuery = graphql`
    query OurTeamPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            frontmatter {
                title
                introduction
                backgroundImage {
                    extension
                    publicURL
                }
                featuredImage {
                    childImageSharp {
                        fluid(maxWidth: 1400, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                leadershipTeamHeading
                leadershipTeam {
                    name
                    title
                    bio
                    headshot {
                        childImageSharp {
                            fluid(maxWidth: 270, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                boardDirectorsHeading
                boardOfDirectors {
                    name
                    title
                    bio
                    headshot {
                        childImageSharp {
                            fluid(maxWidth: 270, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                scientificAdvisoryBoardHeading
                scientificAdvisoryBoard {
                    name
                    title
                    bio
                    headshot {
                        childImageSharp {
                            fluid(maxWidth: 270, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                teamHeading
                team {
                    name
                    title
                    headshot {
                        childImageSharp {
                            fluid(maxWidth: 270, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                logoGrid {
                    heading
                    logoGridImages {
                        title
                        logo {
                            childImageSharp {
                                fluid(maxWidth: 300, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
